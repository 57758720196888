import App from './App.ce.vue'
import store from "@/store/index";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { defineCustomElement as VueDefineCustomElement, h, createApp, getCurrentInstance } from 'vue'



const g = document.createElement('div');
g.setAttribute("id", "app");
document.querySelector('body').append(g);

createApp(App).use(store).mount('#app')

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    wsHost: process.env.VUE_APP_WS_HOST,
    wsPort: 6001,
    cluster: "mt1",
    forceTLS: false,
});
