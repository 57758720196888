import axios from 'axios';

const API_URL = process.env.VUE_APP_BASE_URL;

class AuthService {
    login(user) {
        return axios.post(API_URL + '/login', {
            username: user.username,
            chatbot_uuid: user.chatbot_uuid
        });
    }
}

export default new AuthService();